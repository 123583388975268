.main{
    display: grid;
    place-items: center;
    width: 100%;
    gap: 2rem;
    padding: 1rem 2rem;
}
.steps{
    display: grid;
    place-items: center;
    gap: .3rem;
}
.steps h3{
    font-weight: 600;
    font-size: .87rem;
    line-height: 1.3rem;
    color: rgba(0, 0, 0, 1);
}
.steps p:nth-child(2){
    font-weight: 600;
    font-size: 1rem;
    line-height: 1.5rem;
    color: rgba(0, 0, 0, 0.77);
}
.steps p:nth-child(3){
    font-weight: 400;
    font-size: .87rem;
    line-height: 1.3rem;
    color: rgba(0, 0, 0, 1);
}
.stepone {
    width: 100%;
    display: grid;
    gap: 1rem;
}
.hide{
    display: none;
}
.upload{
    display: grid;
    place-items: center;
    color: rgba(129,14,167.1);
    font-weight: 600;
    font-size: .75rem;
    gap: .5rem;
    /* background-color: red; */
    width: 9.25rem;
    margin: 0 auto;
}
.img{
    width: 9.25rem;
    height: 7.9rem;
}
.grid{
    display: grid;
    width: 100%;
    gap: 1rem;
}
.grid input{
    width: 100%;
    border: 1px solid rgba(129, 14, 167, 0.5);
    border-radius: .63rem;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.5rem;
    color: rgba(45,45,45,1);
    padding: .6rem 1.3rem;
}
.footer{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.footer > button{
    background-color: rgba(129, 14, 167, 1);
    width: 6.6rem;
    border-radius: .6rem;
    border: none;
    color: rgba(255, 255, 255, 1);
    font-weight: 700;
    font-size: 1rem;
    line-height: 1.5rem;
    padding: .6rem 2rem;
}
.footer p{
    color: rgba(129, 14, 167, 1);
    font-weight: 700;
    font-size: 1rem;
    line-height: 1.5rem;
}
@media screen and (min-width:850px) {
    .main{
        gap: 2rem;
        padding: 1.5rem 3rem;
    }
    .stepone {
        /* grid-template-columns: 1fr 1fr 1fr; */
        display: flex;
        column-gap: 2rem;
        justify-content: center;
        align-items: center;
    }
}