.loginbody{
    background:  url('../images/registerbg.png') center/cover no-repeat fixed;
    min-height: 90vh;
    position: relative;
    display: grid;
    place-items: center;
    z-index: 10;
}
.overlay{
    height: 100%;
    width: 100%;
    background-color: rgba(129, 14, 167, 0.6);
    position: absolute;
    top: 0;
    backdrop-filter: blur(9px);
    z-index: -10;
}
.form{
    background-color: #ffffff;
    width: 90%;
    padding: 2rem;
    border-radius: .7rem;
    width: 90%;
    display: grid;
    gap: 1rem;
    
}
.logodiv{
    display: grid;
    place-items: center;
}
.logodiv h5{
    font-size: 1rem;
    font-weight: 600;
    line-height: 1.5rem;
}
.logodiv p{
    font-size: 1rem;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.57);
    line-height: 1.5rem;
}
.warmbook{
    font-size: 1rem;
    line-height: 1.5rem;
    font-weight: 800;
    color: rgba(129, 14, 167, 1);
}
.img{
    height: 4.5rem;
    width: 4.25rem;
}
.input{
    border: 1px solid rgba(129, 14, 167, 0.5);
    border-radius: .6rem;
    width: 100%;
    height: 3rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5rem;
    padding: .6rem 1.3rem;
}
.links{
    display: flex;
    justify-content: space-between;
    color: rgba(129, 14, 167, 1);
    font-weight: 700;
    font-size: .75rem;
    line-height: 1.13rem;
}
.sign{
    width: 100%;
    padding: .6rem 0;
    font-weight: 700;
    font-size: 1rem;
    line-height: 1.5rem;
    background-color: rgba(129, 14, 167, 1);
    color: rgba(255, 255, 255, 1);
    border: none;
    border-radius: .6rem;
}
.google{
    width: 100%;
    padding: .6rem 0;
    font-weight: 500;
    font-size: 1rem;
    line-height: 1.5rem;
    background-color: transparent;
    color: rgba(0, 0, 0, 1);
    border-radius: .6rem;
    border: 1px solid rgba(129, 14, 167, 1);
}
.lineflex {
    text-align: center;
    width: 90%;
    margin: 0 auto;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    font-weight: 400;
    font-size: .9rem;
    line-height: 1.3rem;
}
.lineflex p{
    color: rgba(0, 0, 0, 1);;
}
.line {
    width: 7.7rem;
    height: 1px;
    background-color: rgba(129, 14, 167, 0.53);
}
@media screen and (min-width:700px) {
    .form {
        width: 30rem;
        padding: 2.5rem 3rem;
    }
}