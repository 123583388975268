.registerbody {
    background: url('../images/registerbg.png') center/cover no-repeat fixed;
    min-height: 90vh;
    position: relative;
    display: grid;
    place-items: center;
    z-index: 10;
    gap: 1rem;
    padding: 2rem 0;
}
.overlay {
    height: 100%;
    width: 100%;
    background-color: rgba(129, 14, 167, 0.6);
    position: absolute;
    top: 0;
    backdrop-filter: blur(9px);
    z-index: -10;
}
.logodiv {
    display: grid;
    place-items: center;
}
.h2{
    color: rgba(129, 14, 167, 1);
}
.img{
    width: 4.5rem;
    height: 4.25rem;
}
.p{
    color: rgba(255,255,255,1);
    font-weight: 600;
    font-size: 1rem;
    line-height: 1.5rem;
}
.form {
    background-color: white;
    width: 90%;
    border-radius: .5rem;
    overflow: hidden;
}
.single{
    background: linear-gradient(to right, rgba(221, 113, 255, 1), rgba(175, 65, 199, 1), rgba(113, 0, 124, 1));
    color: whitesmoke;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 2.5rem;
    font-weight: 700;
    font-size: 1rem;
    line-height: 1.5rem;
}
.relative {
    display: none;
}
.absolute {
    background: linear-gradient(to right, rgba(221, 113, 255,1), rgba(175, 65, 199,1), rgba(113, 0, 124,1));
    height: 100%;
    width: 36%;
    border-bottom-right-radius: 5rem;
    border-top-right-radius: 5rem;
}
.flex {
    display: flex;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
.flex p{
    height: 100%;
    width: 33.33%;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 700;
    font-size: 1rem;
    line-height: 1.5rem;
}
.flex p span, .single p span {
    padding-right: 1rem;
}
.white{
    color: whitesmoke;
}
.black{
    color: black;
}
@media screen and (min-width:850px) {
    .form {
        max-width: 60rem;
    }
    .relative{
        display: block;
        width: 100%;
        position: relative;
        height: 2.5rem;
        background-color: rgba(230, 159, 255, 1);
    }
    .single{
        display: none;
    }
}